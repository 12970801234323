@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Jersey+25&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

.font-1 {
  font-family: "Poppins", sans-serif;
}


.font-2 {
  font-family: "Ubuntu", sans-serif;
  
  
}



.font-3 {
    font-family: "Jersey 25", sans-serif;
  
  
}

.custom-shape-divider-bottom-1713799638 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1713799638 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.custom-shape-divider-bottom-1713799638 .shape-fill {
  fill: #EEEEEE;
}

input.middle:focus {
  outline-width: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

option {

    display: block;
    margin: 0 auto;

}
.cmb {
  display: block;
  margin: 0 auto;
}


.custom-shape-divider-bottom-1713824513 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1713824513 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 181px;
}

.custom-shape-divider-bottom-1713824513 .shape-fill {
  fill: #B3A394;
}


